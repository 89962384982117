export const myHost = {
    is
};

export enum Hosts {
    Crm, Payments
}

function is(): Hosts {
    const host = window.location.host
    switch (host) {
        case "trustatepayments.com":
            return Hosts.Payments
        case "test.trustate.ru":
        case "trustate.ru":
            return Hosts.Crm
        default:
            return Hosts.Payments
            //return Hosts.Crm
    }
}
