export class ObjectViewDto {
  id?: number | null
  referenceNumber?: number | null
  objectSubTypeId?: number | null
  subTypeObjectTypeId?: number | null
  // offer
  forSale?: boolean | null
  forRent?: boolean | null
  forShortRent?: boolean | null
  salePrice?: number | null
  rentPrice?: number | null
  shortRentPrice?: number | null

  // main features
  area?: number | null
  bedrooms?: number | null
  bathrooms?: number | null
  description?: string | null

  // location
  address?: string | null
  displayAddress?: boolean | null
  locationId?: number | null
  location?: string | null
  zipCode?: string | null
  distanceFromAirport?: number | null
  distanceFromSea?: number | null
  zone?: number | null
  orientation?: string[] | null
  accessFrom?: number | null
  latitude?: number | null
  longitude?: number | null
  displayCoordinates?: boolean | null
  mapZoom?: number | null
  mapCircleLatitude?: number | null
  mapCircleLongitude?: number | null
  mapCircleRadius?: number | null

  // service information
  active?: boolean | null
  published?: boolean | null
  lastUpdate?: string | null

  // rent details
  student?: boolean | null
  petsWelcome?: boolean | null

  // apartment details
  floor?: number | null
  elevator?: boolean | null
  penthouse?: boolean | null

  // house details
  levels?: number | null
  swimmingPool?: boolean | null
  fireplace?: boolean | null
  solarWaterHeating?: boolean | null
  garden?: boolean | null
  lotArea?: number | null
  buildableArea?: number | null

  // technical details
  heating?: string | null
  heatingMedium?: string | null
  energyClass?: string | null
  framesType?: string | null
  floorsType?: string | null
  doubleGlass?: boolean | null
  airCondition?: boolean | null
  underFloorHeating?: boolean | null

  // property condition
  constructionYear?: number | null
  underConstruction?: boolean | null
  renovated?: boolean | null
  renovationYear?: number | null
  requiredRenovation?: boolean | null
  newDevelopment?: boolean | null

  // offer details
  dateAvailable?: Date | null
  furnished?: boolean | null
  averageMonthlySharedExpenses?: string
  suitableForCommercialUse?: boolean | null
  noAgentFeeForBuyer?: boolean | null

  // parking
  parking?: boolean | null
  parkingLots?: number | null

  // other details
  hasKey?: boolean | null
  hasBanner?: boolean | null
  luxurious?: boolean | null
  wC?: string
  livingRooms?: string
  kitchens?: string
  sizeOfBalconies?: string
  internalStaircase?: boolean | null
  playroom?: boolean | null
  secureDoor?: boolean | null
  corner?: boolean | null
  preserved?: boolean | null
  nightPower?: boolean | null
  awning?: boolean | null
  airy?: boolean | null
  balcony?: boolean | null
  storageSpace?: boolean | null
  attic?: boolean | null
  view?: boolean | null
  alarm?: boolean | null
  facade?: boolean | null
  investment?: boolean | null
  unfinished?: boolean | null
  neoclassical?: boolean | null
  windowScreens?: boolean | null
  bright?: boolean | null
  painted?: boolean | null
  disabledAccess?: boolean | null
  internalNotes?: string

  // tracking
  createdBy?: string
  createdOn?: string | null
  modifiedBy?: string
  modifiedOn?: string | null

  // images
    images?: string[] | null

    accounts: ObjectAccountDto[] = []

  public constructor(init?: Partial<ObjectViewDto>) {
    Object.assign(this, init)
  }

  get isResidential(): boolean | undefined {
    if (this.objectSubTypeId === null || this.objectSubTypeId === undefined) return undefined
    return this.objectSubTypeId <= 6
  }

  get isResidentialAnyRent(): boolean | undefined | null {
    if (this.objectSubTypeId === null || this.objectSubTypeId === undefined) return undefined
    return this.objectSubTypeId <= 6 && (this.forRent || this.forShortRent)
  }
}

export interface ObjectAccountDto {
    id?: string | null;
    objectId: number;
    accountId: number;
    account: string;
    relation: string;
    relationName: string;
    notes: string;
    startDate?: Date | null;
    endDate?: Date | null;
    price?: number | null;
}
