import FileLinkDto from './FileLinkDto'

export default class AccountDto {
    id?: number | null
    firstName?: string | null
    lastName?: string | null
    middleName?: string | null
    firstNameEng?: string | null
    lastNameEng?: string | null
    middleNameEng?: string | null
    firstNameLocal?: string | null
    lastNameLocal?: string | null
    middleNameLocal?: string | null
    phone?: string | null
    email?: string | null
    internalPassportNumber?: string | null
    passportNumber?: string | null
    address?: string | null
    city?: string | null
    postcode?: string | null
    taxNumber?: string | null
    taxNumberOfOtherCountry?: string | null
    isOwner?: boolean | null
    isClient?: boolean | null
    isEmploye?: boolean | null
    isSource?: boolean | null
    isProfessional?: boolean | null
    isPartnerRealEstate?: boolean | null
    isPartnerTourism?: boolean | null
    isCompany?: boolean | null
    isIndividual?: boolean | null
    purchaseBudgetRangeMin?: number | null
    purchaseBudgetRangeMax?: number | null
    isAttorney?: boolean | null
    isNotary?: boolean | null
    isWorker?: boolean | null
    isElectrician?: boolean | null
    isConstructionEngineer?: boolean | null
    isCleaner?: boolean | null
    isInteriorDesigner?: boolean | null
    isTranslator?: boolean | null
    isAccountant?: boolean | null
    isEconomist?: boolean | null
    isLandscapeDesigner?: boolean | null
    isSecurityService?: boolean | null
    isPlumber?: boolean | null
    isLocksmith?: boolean | null
    isIT?: boolean | null
    isWebDesigner?: boolean | null
    isEnergySupplyCompany?: boolean | null
    isElectricalStore?: boolean | null
    isTechnician?: boolean | null
    isRealEstateAgencyOrBroker?: boolean | null

    numberOfSoldObjects?: number | null
    startDate?: Date | null
    endDate?: Date | null
    filesNew?: string | null
    files?: FileLinkDto[] | null
    hasTransactions?: boolean | null
    isClientDate?: Date | null
    isOwnerDate?: Date | null
    public constructor(init?: Partial<AccountDto>) {
        Object.assign(this, init)
    }
}
